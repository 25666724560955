<template>
  <v-app>
    <Marquee />
    <IndexTopic v-if="rawData" />
    <AwardSlider :awards="rawData.awards" />
    <section class="introduce section-spacing" id="investment">
      <div class="web-content">
        <div class="container">
          <div class="description">
            <component :is='deviceView.desktop ? "h5" : "h4"' class="title bold">{{ $t(Words.HomePioneer) }}</component>
            <div class="text font-body">
              {{ $t(Words.HomePioneerDetail) }}
            </div>
          </div>
          <div class="aside">
            <div class="feature">
              <div class="d-flex align-center" :class="deviceView.xs ? 'justify-center' : '' ">
                <template v-if="enMode">
                  <h5 class="bold">${{assetsSummary}} {{ $t(Words.HomeAumUnit) }}</h5>
                </template>
                <template v-else>
                  <span class="highlight">{{assetsSummary}}</span>
                  <span class="shrink ml-3">{{ $t(Words.HomeAumUnit) }}</span>
                </template>
              </div>
              <div class="text">
                <v-icon icon="paid" size="x-small"></v-icon>
                <span class="font-subtitle ml-2">{{ $t(Words.HomeAumText) }}</span>
                <span class="font-caption ml-1">({{ assetDate }})</span>
              </div>
            </div>
            <div class="feature">
              <div>
                <span class="highlight">{{ $t(Words.HomeSecContent) }}</span>
              </div>
              <div class="text">
                <v-icon icon="account_balance" size="x-small"></v-icon>
                <span class="font-subtitle ml-2">{{ $t(Words.HomeSecText) }}</span>
              </div>
            </div>
            <div class="feature">
              <div>
                <span class="highlight">{{ $t(Words.HomeAimaContent) }}</span>
              </div>
              <div class="text">
                <v-icon icon="workspace_premium" size="x-small"></v-icon>
                <span class="font-subtitle ml-2">{{ $t(Words.HomeAimaText) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="safeguard section-spacing" >
      <div class="web-content">
        <div class="deco-title font-subtitle bold">
          {{ $t(Words.HomeBlueTag1) }}
        </div>
        <component :is='deviceView.desktop ? "h4" : "h3"' class="bold" :class="deviceView.mobile ? 'text-center' : ''">
          {{ $t(Words.HomeRiskTitle) }}
        </component>
        <RiskGraphic />
      </div>
    </div>
    <div class="milestone section-spacing" id="history">
      <Milestone class="web-content" :data="rawData.milestone">
        <template v-slot:title>
          <div class="deco-title font-subtitle bold">
            {{ $t(Words.HomeBlueTag2) }}
          </div>
        </template>
      </Milestone>
    </div>
    <div class="media section-spacing" id="newsroom">
      <div class="web-content">
        <div class="report-wrapper">
          <div class="deco-title font-subtitle bold">
            {{ $t(Words.HomeBlueTag3) }}
          </div>
          <component :is='deviceView.desktop ? "h4" : "h3"' class="bold mb-6" :class="deviceView.mobile ? 'text-center' : ''">
            {{ $t(Words.AboutNewsroom) }}
          </component>
          <Report />
        </div>
      </div>
    </div>
    <div class="related" id="information">
      <div class="web-content" style="max-width: 964px;">
        <BrandSlider />
      </div>
    </div>

    <div class="service section-spacing">
      <div class="web-content">
        <component :is='deviceView.desktop ? "h4" : "h3"' class="bold text-center">{{ $t(Words.HomeAgencyTitle) }}</component>
        <div class="wrap">
          <div class="company">
            <div class="logo">
              <img src="~assets/images/hsbc.png" alt="">
            </div>
            <div class="content font-body">
              {{ $t(Words.HomeAgency1) }}
            </div>
          </div>
          <div class="company">
            <div class="logo">
              <img src="~assets/images/bnp-logo.png" alt="" class="bnp-logo">
            </div>
            <div class="content font-body">
              {{ $t(Words.HomeAgency2) }}
            </div>
          </div>
          <div class="company">
            <div class="logo">
              <img src="~assets/images/pwc.png" alt="">
            </div>
            <div class="content font-body">
              {{ $t(Words.HomeAgency3) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script lang="ts" setup>
import { loadHome } from '@/api/about'
import moment from 'moment'

const deviceView = useDeviceView()
const currentMember = useMember()
const { english: enMode } = useViewMode()
const rawData = ref()

// Page Meta
setPageMeta({
  zh_title: '首頁',
  zh_description: 'UG於1998年成立，經歷多空景氣循環，長期績效穩健。',
  en_title: 'Home',
  en_description: 'Founded in 1998, UG was one of the pioneers in the region with solid long-term track record.',
})

const assetsSummary = computed(()=> {
  if (!rawData.value.sum_assets) return 0
  // 資料為百萬美元
  // B = 百萬美元 / 1000
  // 億 = 百萬美元 / 100
  if(enMode.value) return useFilter().round((rawData.value.sum_assets / 1000), 1)
  return useFilter().round((rawData.value.sum_assets / 100), 1)
})

const assetDate = computed(() => {
  return moment(rawData.value.asset_date).format('YYYY-MM')
})

await loadHome().then(({data, error}) => {
  if (data.value) {
    rawData.value = data.value
  } else {
    handleFatal(error)
  }
})

</script>

<style lang="stylus" scoped>
// 全頁共用
.section-spacing
  padding 96px 0
  +under(md)
    padding 48px 0
.deco-title
  position relative
  color $primary
  padding-top 16px
  margin-bottom 16px
  +under(md)
    text-align center
  &:before
    content ''
    display block
    position absolute
    width 40px
    height 4px
    top 0
    background linear-gradient(145.06deg, #2196F3 0%, #1469CD 100%)
    +under(md)
      left 50%
      transform translateX(-50%)

.introduce
  background #37474F
  .container
    +over(lg)
      display flex
      justify-content space-between
  .description
    +over(lg)
      width 371px
    .title
      color #fff
    .text
      margin-top 16px
      color $gray-lighten-2
  .aside
    +over(lg)
      flex-grow 1
      max-width 666px
    +under(lg)
      margin-top 48px
    +over(sm)
      display flex
  .feature
    flex 1 1 0
    color #fff
    background #263238
    padding 16px
    +under(md)
      padding 12px
    +under(sm)
      text-align center
    +.feature
      +over(sm)
        border-left 1px solid #37474F
      +under(sm)
        border-top 1px solid #37474F
    .highlight
      font-size 24px
      line-height 32px
      letter-spacing 0.25px
      font-weight bold
      +under(md)
        font-size 20px
    .shrink
      font-size 20px
      line-height 32px
      letter-spacing 0.25px
      +under(md)
        font-size 16px
    .text
      display flex
      align-items center
      color $gray-lighten-1
      margin-top 12px
      +under(sm)
        justify-content center
// 交易風控
.safeguard
  .description
    color $black-0-6
    margin 24px 0 48px
    +under(md)
      text-align center

// 發展歷程
.milestone
  background $gray-lighten-5
// 媒體報導
.report-wrapper
  background $gray-lighten-5
  padding 48px
  border-radius 24px
  +under(md)
    padding 24px
// 淨值查詢
.related
  background $gray-lighten-5
  padding 24px 0

// 服務機構
.service
  background linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.8) 50.52%, #FFFFFF 100%), url(@/assets/images/index-banner.jpeg)
  background-size cover
  background-position center center
  .wrap
    margin-top 24px
    +over(md)
      display flex
      gap 24px
  .company
    display flex
    flex-direction column
    flex 1 1 0
    +under(md)
      +.company
        margin-top 24px
    .logo
      position relative
      height 140px
      background $gray-lighten-5
      img
        position absolute
        left 50%
        top 50%
        transform translate(-50%, -50%)
    .bnp-logo
      height 60px
  .content
    display flex
    align-items center
    flex 1 1 0
    padding 16px
    background $gray-lighten-4
</style>