<template>
  <div class="page-layout">
    <div class="page-content">
      <div class="maintenance-page">
        <div class="text">
          <h1>網站系統升級，服務暫停中</h1>
          <h1 class="secondary">Website Maintenance Notice</h1>
          <p>
            為了提供更好的服務品質，本網站 於 2025/1/4 (六) 上午 8:00 ~ 10:00 進行網站系統升級。
          </p>
          <p>
            造成您的不便，UG 感謝您的耐心與支持！
          </p>
          <p class="secondary" style="margin-top: 2em">To provide better service quality, our website will undergo a system upgrade on 4 January 2025 (Sat.), from 8:00 am to 10:00 am. (GMT+8)</p>
          <p class="secondary">Thank you for your understanding, and we sincerely appreciate your patience and support.</p>
        </div>
        <span class="web-logo" v-track:click="'Header,logo'">
          <img src="~/assets/images/ug-logo.png" alt="UG logo" class="logo">
          <img src="~/assets/images/ug-word.png" alt="UG INVESTMENT ADVISERS" class="word">
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>


</script>

<style lang="stylus" scoped>
.maintenance-page 
  min-height 100vh
  display flex
  align-items center
  flex-direction column
  justify-content center

.web-logo
  display flex
  align-items center
  padding 8px 11px
  img.logo
    width 30px
  img.word
    height 14px
    margin-left 16px

h1
  min-height 1rem
  font-size 2rem
  font-weight bold
  margin 2em 0
  line-height 1em
  &:first-child
    margin-top 0
  &.secondary
    margin-top -2em
    color $gray
    font-size 1.5rem

.text 
  color #333
  line-height 1.8
  margin 2rem auto
  padding 1rem

p 
  color #666
  font-size 1.2rem
  line-height 1.2
  margin 1rem 0

.secondary
  color $gray

</style>