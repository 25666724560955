<template>
  <footer>
    <div class="web-content">
      <div class="web-logo">
        <img src="~/assets/images/ug-logo.png" alt="UG logo" class="logo">
        <img src="~/assets/images/ug-word.png" alt="UG INVESTMENT ADVISERS" class="word">
      </div>
      <div class="mobile-links">
        <div class="info-box d-flex align-center" style="gap: 24px;">
          <template v-for="link in links">
            <nuxt-link v-if="link.isIndex" :to="localePath('/')" class="web-link font-body">{{ $t(link.title) }}</nuxt-link>
            <nuxt-link v-else-if="link.isMail" to="mailto:info@UGfunds.com" class="web-link font-body">{{ $t(link.title) }}</nuxt-link>
            <nuxt-link v-else :to="localePath({name: link.route})" class="web-link font-body">{{ $t(link.title) }}</nuxt-link>
          </template>
        </div>
      </div>
      <div class="infos">
        <div class="info-box contact">
          <div class="mb-6">
            <div class="title font-caption">{{ $t(Words.FooterCaption1) }}</div>
            <div class="content">
              <v-btn href="mailto:info@UGfunds.com" target="_blank" v-track:click="'Footer,email:fund_information'" variant="text" size="small">
                <v-icon icon="content_copy" size="18" class="mr-2" />
                {{ ('info@UGfunds.com').toUpperCase() }}
              </v-btn>
            </div>
          </div>
          <div>
            <div class="title font-caption">{{ $t(Words.FooterCaption2) }}</div>
            <div class="content mb-3">
              <v-btn href="mailto:compliance@UGfunds.com" target="_blank" v-track:click="'Footer,email:legal_compliance'" variant="text" size="small">
                <v-icon icon="content_copy" size="18" class="mr-2" />
                {{ ('compliance@UGfunds.com').toUpperCase() }}
              </v-btn>
            </div>
            <div class="content address">
              <v-icon variant="text" icon="location_on" size="18" color="rgba(0, 0, 0, 0.54)" class="ml-2 mr-3" />
              <address>
                PO Box 309, Ugland House <br>
                Grand Cayman, KY1-1104, Cayman Islands
              </address>  
            </div>
          </div>
        </div>
        <div class="info-box certification">
          <div class="title font-caption">{{ $t(Words.FooterCaption3) }}</div>
          <img src="~/assets/images/logo-AIMA.png" alt="" class="aima">
        </div>
        <div v-if="deviceView.desktop" class="info-box">
          <div class="title font-caption">{{ $t(Words.FooterCaption4) }}</div>
          <template v-for="link in links">
            <nuxt-link v-if="link.isIndex" :to="localePath('/')" v-track.click="link.track" class="web-link font-body">{{ $t(link.title) }}</nuxt-link>
            <nuxt-link v-else-if="link.isMail" to="mailto:info@UGfunds.com" v-track.click="link.track" class="web-link font-body">{{ $t(link.title) }}</nuxt-link>
            <nuxt-link v-else :to="localePath({name: link.route})" v-track.click="link.track" class="web-link font-body">{{ $t(link.title) }}</nuxt-link>
          </template>
        </div>
      </div>
      <div class="info-box copyright font-body-2">
        © {{  year  }} UG Investment Advisers All Rights Reserved.
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
const deviceView = useDeviceView()
const localePath = useLocalePath()
const { english: enMode } = useViewMode()
</script>

<script lang="ts">
export default defineComponent({
  data() {
    return {
      links: [
        { title: Words.MenuAbout, isIndex: true, track: 'Footer,sitelink:about' },
        { title: Words.MenuAward, route: 'awards', track: 'Footer,sitelink:awards' },
        { title: Words.MenuRecruit, route: 'recruit', track: 'Footer,sitelink:recruit' },
        { title: Words.MenuContact, isMail: true, track: 'Footer,email:contact' },
      ]
    }
  },
  computed: {
    year() {
      const date = new Date
      return date.getFullYear()
    }
  },
})
</script>

<style lang="stylus" scoped>
footer
  padding 12px 0
  background $gray-lighten-5
  .web-logo
    display flex
    align-items center
    padding 12px
    .logo
      width 28px
    .word
      margin-left 16px
      height 14px
  .infos
    +over(sm)
      display flex
      justify-content space-between
    padding 12px 0
    border-top 1px solid $black-0-06
    border-bottom 1px solid $black-0-06
  .info-box
    padding 12px
    gap 16px
    .title
      color $black-0-38
      margin-bottom 12px
    :deep(.content)
      padding-left 16px
      .v-btn
        color $black-0-87
        opacity 0.62
        font-weight bold
    .content.address
      display flex
      align-items center
      color $black-0-54
      address
        font-style normal
        font-weight bold
        font-size 13px
  .contact, .certification
    flex 1 1 0
  img.aima
    width 100px
  .web-link
    display block
    color $black-0-6
    +over(md)
      width 150px
    +.web-link
      +over(md)
        margin-top 12px
  .copyright
    color $black-0-38
    margin-top 12px
  .mobile-links
    +over(md)
      display none
    padding 12px 0
    border-top 1px solid $black-0-06

+under(md)
  footer
    .contact
      flex 2 1 0

// 不要列印 Header, Footer
@media print
  footer
    display none
    // background none
    // position fixed
    // bottom 0
    // left 0 
    // right 0
    // display table-footer-group
    
    // .web-content 
    //   > *:not(.copyright)
    //     display none
    //   .copyright
    //     padding 0
    //     text-align center
</style>